.container {
  width: 100%;

  .compContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    //take all space


    .uploadExcelPart {
      display: flex;
      gap: 50px;
      align-items: center;


      .alertMessage {
        //margin: 10px 0;
      }

      .uploadTitle {
        font-size: 13px;
        font-weight: 600;
        color: #202020;

      }
    }
  }

  .tableContainer {

  }
  .addNewInfluencerContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap:10px;
    margin-bottom: 20px;
    align-items: center;
  }
}